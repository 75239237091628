import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import classes from "../menu.module.scss"
import {
  getBarMenuPeriodAction,
  getMenuPeriodAction,
  getWineMenuPeriodAction
} from "redux/marketing/actions"
import { Typography } from "@mui/material"
import { marketingActions } from "redux/marketing/slice/user"
import MenuNavBar from "components/menuNavBar"
import SubMenuBar from "components/subMenuBar"
import AddMenuItems from "../AddMenuItems"
import Card from "components/Card/Card"
import CategoryModal from "../EditMenu/components/CategoryModal"
import BarMenuCategoryModal from "../EditMenu/components/BarMenuCategoryModal"
import ArchivedMenuListContainer from "components/ArchivedMenuListContainer"
import ArchivedWineListItems from "components/ArchivedWineListItems"
import ArchivedBarListItems from "components/ArchivedBarListItems"
import { getArchivedSpiritCMSAction } from "redux/marketing/actions/user"
import ArchivedSpiritListItems from "components/ArchivedSpiritListItems"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import Masonry from "react-responsive-masonry"

const menuText = {
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch",
  BARMENU: "bar menu",
  WINEBAR: "wine by glass",
  SPIRITS: "spirits"
}

function EditMenu() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  //   const history = useHistory()
  const [categoryModal, setCategoryModal] = useState(false)
  const [isEdit] = useState(true)
  const [currentCategoryData, setCurrentCategoryData] = useState({})
  const [isEditModal, setIsEditModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const { linkCategory } = useSelector((state) => state?.marketing?.menu || {})
  const { menuPeriod, barMenuPeriod, wineMenuPeriod, spiritData } = useSelector(
    (state) => state?.marketing?.menu || []
  )

  const { menuCategory } = useSelector((state) => state.dmv)

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Archived Items"
    return () => dispatch(marketingActions.setFoodItemsDetail({}))
  }, [])

  useEffect(() => {
    const payload = {
      archive: true
    }
    if (linkCategory?.item === menuText.BARMENU) {
      dispatch(getBarMenuPeriodAction(payload))
    } else if (linkCategory?.item === menuText.WINEBAR) {
      dispatch(getWineMenuPeriodAction(payload))
    } else if (linkCategory?.item === menuText.SPIRITS) {
      dispatch(getArchivedSpiritCMSAction(payload))
    } else {
      dispatch(getMenuPeriodAction(payload))
    }
  }, [linkCategory?.item, linkCategory?.category])

  return (
    <Fragment>
      <AddMenuItems
        isEdit={isEditModalItem}
        open={menuItemsModal}
        onClose={handleCloseMenuItems}></AddMenuItems>
      {(linkCategory?.item === menuText.DINNER ||
        linkCategory?.item === menuText.LUNCH ||
        linkCategory?.item === menuText.BRUNCH) && (
        <CategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}></CategoryModal>
      )}
      {linkCategory?.item === menuText.BARMENU && (
        <BarMenuCategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}></BarMenuCategoryModal>
      )}
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title={`Archived Items`} breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="12">
            <MenuNavBar isEdit={isEdit} activeTab={linkCategory?.item}></MenuNavBar>
          </Col>
        </Row>
        {(linkCategory?.item === menuText.DINNER ||
          linkCategory?.item === menuText.LUNCH ||
          linkCategory?.item === menuText.BRUNCH) && (
          <Row className="mt-4">
            <Col lg="12">
              <SubMenuBar isEdit={isEdit} activeTab={linkCategory?.category}></SubMenuBar>
            </Col>
          </Row>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <Row className="mt-4">
            <Col lg={12}>
              <SpiritsSubMenuBar
                isCMS={true}
                isArchived={true}
                activeTab={menuCategory?.name}
                isEdit={true}
              />
            </Col>
          </Row>
        )}
      </Container>
      <Container fluid className="p-0 mt-4">
        <Row className="m-0 mt-2 mb-4 pb-2" style={{ borderBottom: "1px solid gray" }}>
          <Col lg="12" className="p-0 d-flex justify-content-between align-items-center">
            <Typography variant="h2">
              {linkCategory?.item === menuText.DINNER ||
              linkCategory?.item === menuText.LUNCH ||
              linkCategory?.item === menuText.BRUNCH
                ? linkCategory?.category
                : ""}
            </Typography>
          </Col>
        </Row>
        {(linkCategory?.item === menuText.DINNER ||
          linkCategory?.item === menuText.LUNCH ||
          linkCategory?.item === menuText.BRUNCH) && (
          <Masonry columnsCount={2} gutter={24} className={classes.items}>
            {menuPeriod?.length ? (
              menuPeriod?.map((menuItem, idx) => {
                return (
                  <ArchivedMenuListContainer
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}></ArchivedMenuListContainer>
                )
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </Masonry>
        )}
        {linkCategory?.item === menuText.BARMENU && (
          <Masonry columnsCount={2} gutter={24} className={classes.items}>
            {barMenuPeriod?.length ? (
              barMenuPeriod?.map((menuItem, idx) => {
                return (
                  <ArchivedBarListItems
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}></ArchivedBarListItems>
                )
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </Masonry>
        )}
        {linkCategory?.item === menuText.WINEBAR && (
          <Card>
            <div className={classes.menuContainer}>
              {wineMenuPeriod?.length ? (
                wineMenuPeriod?.map((menuItem, idx) => {
                  return (
                    <ArchivedWineListItems
                      isEdit={isEdit}
                      foodList={menuItem}
                      key={idx}></ArchivedWineListItems>
                  )
                })
              ) : (
                <Typography>No Data Found</Typography>
              )}
            </div>
          </Card>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <Container fluid className="p-0 mt-4">
            <div className={classes.menuContainer}>
              {spiritData?.length ? (
                spiritData?.map((menuItem, idx) => {
                  return (
                    <ArchivedSpiritListItems
                      isEdit={isEdit}
                      isBarItem={false}
                      data={menuItem}
                      key={idx}
                      setMenuItemsModal={setMenuItemsModal}
                      setCurrentCategoryData={setCurrentCategoryData}
                      setIsEdit={setIsEditModal}
                      setIsEditModalItem={setIsEditModalItem}
                      setCategoryModal={setCategoryModal}
                      isArchived={true}></ArchivedSpiritListItems>
                  )
                })
              ) : (
                <Typography>No Data Found</Typography>
              )}
            </div>
          </Container>
        )}
      </Container>
    </Fragment>
  )
}

export default EditMenu
