import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import classes from "../menu.module.scss"
import MenuListContainer from "../../../../../components/menuListContainer/index"
import {
  getBarMenuPeriodAction,
  getMenuPeriodAction,
  getWineMenuPeriodAction
} from "redux/marketing/actions"
import { Typography, Grid } from "@mui/material"
import { useHistory } from "react-router-dom"
import { useTheme } from "@mui/system"
import { marketingActions } from "redux/marketing/slice/user"
import CategoryModal from "./components/CategoryModal"
import MenuNavBar from "components/menuNavBar"
import SubMenuBar from "components/subMenuBar"
import WineListItems from "components/WineListItems"
import Card from "components/Card/Card"
import BarListItems from "components/BarListItem"
import BarMenuCategoryModal from "./components/BarMenuCategoryModal"
// import ImportSpiritsModal from "components/ImportSpirits"
import SpiritsMenuListContainer from "components/spiritsMenuListConatiner"
import { getNewSpiritCountAction, getSpiritCMSAction } from "redux/marketing/actions/user"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import DMVAddMenuItems from "pages/private/DVM_Console/DMV_Menu/AddDMVMenuItems"
import AddMenuItems from "../AddMenuItems"
import { trackAddCategoryClick, trackImportSpiritClick } from "helpers/google_event_trackers"
import DMV_SpiritSummary from "components/DMVSpiritSummary"
import Masonry from "react-responsive-masonry"

const buttonText = {
  CATEGORY: "+ Add Category",
  ITEM: "+ Add Item",
  IMPORT_SPIRITS: "Import Spirits"
}

const menuText = {
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch",
  BARMENU: "bar menu",
  WINEBAR: "wine by glass",
  SPIRITS: "spirits"
}

function EditMenu() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const [categoryModal, setCategoryModal] = useState(false)
  const [isEdit] = useState(true)
  const [currentCategoryData, setCurrentCategoryData] = useState({})
  const [isEditModal, setIsEditModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const { linkCategory } = useSelector((state) => state?.marketing?.menu || {})
  // const [importSpiritModal, setImportSpiritModal] = useState(false)
  const { menuPeriod, barMenuPeriod, wineMenuPeriod, spiritData } = useSelector(
    (state) => state?.marketing?.menu || []
  )
  const { full_name } = useSelector((store) => store.user?.userData)
  // const { newSpiritCount } = useSelector((state) => state?.marketing?.menu || [])
  const { menuCategory, SpiritSubMenu } = useSelector((state) => state.dmv)

  const handleRouting = () => {
    const itemsLink = {
      item: linkCategory?.item,
      category: "Entrees",
      list: "Summary View"
    }
    dispatch(marketingActions.setMenuLink(itemsLink))
    history.push("/marketing/dashboard/explore-menu")
  }

  function handleAddCategory(btnValue) {
    if (btnValue?.trim() === buttonText.CATEGORY) {
      setCategoryModal(true)
      setIsEditModal(false)
      setCurrentCategoryData(false)
      setIsEditModalItem(false)
      dispatch(marketingActions.setFoodItemsDetail({}))
      trackAddCategoryClick(full_name)
    } else if (btnValue?.trim() === buttonText.ITEM) {
      setMenuItemsModal(true)
    } else {
      // setImportSpiritModal(true)
      trackImportSpiritClick(full_name)
    }
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu"
    return () => dispatch(marketingActions.setFoodItemsDetail({}))
  }, [])

  useEffect(() => {
    if (linkCategory?.item === menuText.BARMENU) {
      dispatch(getBarMenuPeriodAction())
    } else if (linkCategory?.item === menuText.WINEBAR) {
      dispatch(getWineMenuPeriodAction())
    } else if (linkCategory?.item === menuText.SPIRITS) {
      dispatch(getSpiritCMSAction())
      dispatch(getNewSpiritCountAction())
    } else {
      dispatch(getMenuPeriodAction())
    }
  }, [linkCategory?.item, linkCategory?.category, menuCategory])

  return (
    <Fragment>
      {/* {importSpiritModal && <ImportSpiritsModal onClose={() => setImportSpiritModal(false)} />} */}
      {linkCategory?.item === menuText?.SPIRITS ? (
        <DMVAddMenuItems
          isEdit={isEditModalItem}
          open={menuItemsModal}
          onClose={handleCloseMenuItems}
          isCMS={true}></DMVAddMenuItems>
      ) : (
        <AddMenuItems
          isEdit={isEditModalItem}
          open={menuItemsModal}
          onClose={handleCloseMenuItems}
        />
      )}
      {(linkCategory?.item === menuText.DINNER ||
        linkCategory?.item === menuText.LUNCH ||
        linkCategory?.item === menuText.BRUNCH) && (
        <CategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}></CategoryModal>
      )}
      {linkCategory?.item === menuText.BARMENU && (
        <BarMenuCategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}></BarMenuCategoryModal>
      )}
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title={`Menu`} breadCrumbs={breadCrumbs}>
              <div className="d-flex align-items-center">
                <AppButton
                  styles={{
                    backgroundColor: theme.palette.background.main,
                    padding: "20px 30px"
                  }}
                  variant="filled"
                  onClick={handleRouting}>
                  Done
                </AppButton>
              </div>
            </Header>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="12">
            <MenuNavBar isEdit={isEdit} activeTab={linkCategory?.item}></MenuNavBar>
          </Col>
        </Row>
        {(linkCategory?.item === menuText.DINNER ||
          linkCategory?.item === menuText.LUNCH ||
          linkCategory?.item === menuText.BRUNCH) && (
          <Row className="mt-4">
            <Col lg="12">
              <SubMenuBar isEdit={isEdit} activeTab={linkCategory?.category}></SubMenuBar>
            </Col>
          </Row>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <Row className="mt-4">
            <Col lg={12}>
              <SpiritsSubMenuBar isCMS={true} activeTab={menuCategory?.name} isEdit={true} />
            </Col>
          </Row>
        )}
      </Container>
      <Container fluid className="p-0">
        <Row className="m-0 mt-2 mb-4 pb-2" style={{ borderBottom: "1px solid gray" }}>
          <Col lg="12" className="p-0 d-flex justify-content-between align-items-center">
            <Typography variant="h2">
              {linkCategory?.item === menuText.DINNER ||
              linkCategory?.item === menuText.LUNCH ||
              linkCategory?.item === menuText.BRUNCH
                ? linkCategory?.category
                : ""}
            </Typography>
            {linkCategory?.item !== menuText.SPIRITS && (
              <Grid sx={{ position: "relative" }}>
                <AppButton
                  onClick={(e) => handleAddCategory(e.target.innerText)}
                  styles={{
                    backgroundColor: theme.palette.background.main,
                    padding: "10px 20px"
                  }}>
                  {linkCategory?.item === menuText.DINNER ||
                  linkCategory?.item === menuText.LUNCH ||
                  linkCategory?.item === menuText.BRUNCH ||
                  linkCategory?.item === menuText.BARMENU
                    ? buttonText.CATEGORY
                    : linkCategory?.item === menuText.SPIRITS
                    ? buttonText.IMPORT_SPIRITS
                    : buttonText.ITEM}
                </AppButton>
              </Grid>
            )}
          </Col>
        </Row>
        {(linkCategory?.item === menuText.DINNER ||
          linkCategory?.item === menuText.LUNCH ||
          linkCategory?.item === menuText.BRUNCH) && (
            <Masonry columnsCount={2} gutter={24} className={classes.items}>
            {menuPeriod?.length > 0 ? (
              menuPeriod?.map((menuItem, idx) => {
                return (
                  <MenuListContainer
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}></MenuListContainer>
                )
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </Masonry>
        )}
        {linkCategory?.item === menuText.BARMENU && (
          <Masonry columnsCount={2} gutter={24} className={classes.items}>
            {barMenuPeriod?.length > 0 ? (
              barMenuPeriod?.map((menuItem, idx) => {
                return (
                  <BarListItems
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}></BarListItems>
                )
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </Masonry>
        )}
        {linkCategory?.item === menuText.WINEBAR && (
          <Card>
            <div className={classes.menuContainer}>
              {wineMenuPeriod?.length > 0 ? (
                wineMenuPeriod?.map((menuItem, idx) => {
                  return (
                    <WineListItems isEdit={isEdit} foodList={menuItem} key={idx}></WineListItems>
                  )
                })
              ) : (
                <Typography>No Data Found</Typography>
              )}
            </div>
          </Card>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <>
            <Grid item sx={{ maxWidth: "50%" }}>
              {SpiritSubMenu?.map(
                (foodList, i) =>
                  foodList?.id === menuCategory?.name &&
                  (foodList?.description ||
                    foodList?.image_url ||
                    foodList?.unique_facts?.length > 0) && (
                    <DMV_SpiritSummary key={i} isEdit={false} foodList={foodList} isCMS={true} />
                  )
              )}
            </Grid>
            <Container fluid className="p-0 mt-4">
              <div className={classes.spiritContainer}>
                {spiritData?.length > 0 ? (
                  spiritData?.map((menuItem, idx) => {
                    return (
                      <SpiritsMenuListContainer
                        setMenuItemsModal={setMenuItemsModal}
                        setCurrentCategoryData={setCurrentCategoryData}
                        setIsEdit={setIsEditModal}
                        setIsEditModalItem={setIsEditModalItem}
                        setCategoryModal={setCategoryModal}
                        isEdit={isEdit}
                        data={menuItem}
                        key={idx}></SpiritsMenuListContainer>
                    )
                  })
                ) : (
                  <Typography>No Data Found</Typography>
                )}
              </div>
            </Container>
          </>
        )}
      </Container>
    </Fragment>
  )
}

export default EditMenu
